.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.staticCard{
  display: flex;
  margin: auto;
  width: 50%;
}

@media (max-width: 575px) {
  .staticCard{
    display: flex;
    margin: auto;
    width: 100% !important;
  }
}


.progress-circle {
  --size: 100px;
  --thickness: 5px;
  width: var(--size);
  height: var(--size);
  border-radius: 50%;
  position: relative;
  background: conic-gradient(
    #3b82f6 var(--progress),
    #fffdfd 0
  );
}

.progress-circle::before {
  content: '';
  position: absolute;
  top: var(--thickness);
  left: var(--thickness);
  width: calc(100% - 2 * var(--thickness));
  height: calc(100% - 2 * var(--thickness));
  background-color:#dbeafe;
  border-radius: 50%;
}

.progress-circle span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.2rem;
  font-weight: bold;
}



.progress-circle-big {
  --sizebg: 120px;
  --thicknessBg: 5px;
  width: var(--sizebg);
  height: var(--sizebg);
  border-radius: 50%;
  position: relative;
  background: conic-gradient(
    #3b82f6 var(--progress),
    #fffdfd 0
  );
}

.progress-circle-big ::before {
  content: '';
  position: absolute;
  top: var(--thicknessBg);
  left: var(--thicknessBg);
  width: calc(100% - 2 * var(--thicknessBg));
  height: calc(100% - 2 * var(--thicknessBg));
  background-color:#dbeafe;
  border-radius: 50%;
}

.progress-circle-big span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.4rem;
  font-weight: bold;
}


.spendings-section {
  padding-right: 20px;
}

.spendings-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.spending-card {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.spending-info {
  display: flex;
  flex-direction: column;
}

.spending-title {
  font-weight: bold;
  margin-bottom: 5px;
 
}

.spending-amount {
  color: rgb(254, 251, 251);
}
